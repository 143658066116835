import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncodingService {
  constructor() {}

  utf8ToBase64(utf8: string) {
    return window.btoa(utf8);
  }

  base64ToUtf8(base64: string) {
    return window.atob(base64);
  }

  urlSafeUtf8ToBase64(utf8: string) {
    return this.webSafe64(this.utf8ToBase64(utf8));
  }

  urlSafeBase64ToUtf(base64: string) {
    return this.base64ToUtf8(this.normal64(base64));
  }

  // Convert from normal to web-safe, strip trailing "="s
  webSafe64(base64: string) {
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  // Convert from web-safe to normal, add trailing "="s
  normal64(base64: string) {
    return base64.replace(/-/g, '+').replace(/_/g, '/') + '=='.substring(0, (3 * base64.length) % 4);
  }
}
