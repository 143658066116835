/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PaymentEntity } from '../models/payment-entity';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation paymentsFindAll
   */
  static readonly PaymentsFindAllPath = '/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PaymentEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFindAll(params?: {}, context?: HttpContext): Observable<Array<PaymentEntity>> {
    return this.paymentsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentEntity>>) => r.body as Array<PaymentEntity>),
    );
  }

  /**
   * Path part for operation paymentsFindOne
   */
  static readonly PaymentsFindOnePath = '/payments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PaymentEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PaymentEntity> {
    return this.paymentsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentEntity>) => r.body as PaymentEntity),
    );
  }
}
