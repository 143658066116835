/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTaxDto } from '../models/create-tax-dto';
import { TaxEntity } from '../models/tax-entity';
import { UpdateTaxDto } from '../models/update-tax-dto';

@Injectable({
  providedIn: 'root',
})
export class TaxesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation taxesFindAll
   */
  static readonly TaxesFindAllPath = '/taxes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, TaxesService.TaxesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TaxEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesFindAll(params?: {}, context?: HttpContext): Observable<Array<TaxEntity>> {
    return this.taxesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxEntity>>) => r.body as Array<TaxEntity>),
    );
  }

  /**
   * Path part for operation taxesCreate
   */
  static readonly TaxesCreatePath = '/taxes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxesCreate$Response(
    params: {
      body: CreateTaxDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TaxEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TaxesService.TaxesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TaxEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxesCreate(
    params: {
      body: CreateTaxDto;
    },
    context?: HttpContext,
  ): Observable<TaxEntity> {
    return this.taxesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxEntity>) => r.body as TaxEntity),
    );
  }

  /**
   * Path part for operation taxesFindOne
   */
  static readonly TaxesFindOnePath = '/taxes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TaxEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TaxesService.TaxesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TaxEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<TaxEntity> {
    return this.taxesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxEntity>) => r.body as TaxEntity),
    );
  }

  /**
   * Path part for operation taxesRemove
   */
  static readonly TaxesRemovePath = '/taxes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TaxEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TaxesService.TaxesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TaxEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<TaxEntity> {
    return this.taxesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxEntity>) => r.body as TaxEntity),
    );
  }

  /**
   * Path part for operation taxesUpdate
   */
  static readonly TaxesUpdatePath = '/taxes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxesUpdate$Response(
    params: {
      id: string;
      body: UpdateTaxDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TaxEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TaxesService.TaxesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TaxEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxesUpdate(
    params: {
      id: string;
      body: UpdateTaxDto;
    },
    context?: HttpContext,
  ): Observable<TaxEntity> {
    return this.taxesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxEntity>) => r.body as TaxEntity),
    );
  }
}
