/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InvoiceEntity } from '../models/invoice-entity';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation invoicesFindAll
   */
  static readonly InvoicesFindAllPath = '/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.InvoicesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<InvoiceEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesFindAll(params?: {}, context?: HttpContext): Observable<Array<InvoiceEntity>> {
    return this.invoicesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceEntity>>) => r.body as Array<InvoiceEntity>),
    );
  }

  /**
   * Path part for operation invoicesFindOne
   */
  static readonly InvoicesFindOnePath = '/invoices/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InvoiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.InvoicesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<InvoiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<InvoiceEntity> {
    return this.invoicesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceEntity>) => r.body as InvoiceEntity),
    );
  }
}
