/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateRoomDto } from '../models/create-room-dto';
import { RoomEntity } from '../models/room-entity';
import { UpdateRoomDto } from '../models/update-room-dto';

@Injectable({
  providedIn: 'root',
})
export class RoomsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation roomsFindAll
   */
  static readonly RoomsFindAllPath = '/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<RoomEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, RoomsService.RoomsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RoomEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roomsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsFindAll(params?: {}, context?: HttpContext): Observable<Array<RoomEntity>> {
    return this.roomsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoomEntity>>) => r.body as Array<RoomEntity>),
    );
  }

  /**
   * Path part for operation roomsCreate
   */
  static readonly RoomsCreatePath = '/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roomsCreate$Response(
    params: {
      body: CreateRoomDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RoomEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RoomsService.RoomsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RoomEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roomsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roomsCreate(
    params: {
      body: CreateRoomDto;
    },
    context?: HttpContext,
  ): Observable<RoomEntity> {
    return this.roomsCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoomEntity>) => r.body as RoomEntity),
    );
  }

  /**
   * Path part for operation roomsFindOne
   */
  static readonly RoomsFindOnePath = '/rooms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RoomEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RoomsService.RoomsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RoomEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roomsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RoomEntity> {
    return this.roomsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoomEntity>) => r.body as RoomEntity),
    );
  }

  /**
   * Path part for operation roomsRemove
   */
  static readonly RoomsRemovePath = '/rooms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RoomEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RoomsService.RoomsRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RoomEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roomsRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roomsRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RoomEntity> {
    return this.roomsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoomEntity>) => r.body as RoomEntity),
    );
  }

  /**
   * Path part for operation roomsUpdate
   */
  static readonly RoomsUpdatePath = '/rooms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roomsUpdate$Response(
    params: {
      id: string;
      body: UpdateRoomDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RoomEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RoomsService.RoomsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RoomEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roomsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roomsUpdate(
    params: {
      id: string;
      body: UpdateRoomDto;
    },
    context?: HttpContext,
  ): Observable<RoomEntity> {
    return this.roomsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoomEntity>) => r.body as RoomEntity),
    );
  }
}
