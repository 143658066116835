/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CancelEventDto } from '../models/cancel-event-dto';
import { DeleteEventDto } from '../models/delete-event-dto';
import { EventEntity } from '../models/event-entity';
import { RegistrationEntity } from '../models/registration-entity';
import { UpdateEventDto } from '../models/update-event-dto';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation eventsFindAll
   */
  static readonly EventsFindAllPath = '/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindAll$Response(
    params?: {
      /**
       * The "orderBy" parameter should have the form "startDate:asc,firstname:desc"
       */
      orderBy?: Array<string>;

      /**
       * The "where" parameter should have the form "startDate:gte:2022-12-31,endDate:lt:2023-12-31"
       */
      where?: Array<string>;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<EventEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsFindAllPath, 'get');
    if (params) {
      rb.query('orderBy', params.orderBy, {});
      rb.query('where', params.where, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EventEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindAll(
    params?: {
      /**
       * The "orderBy" parameter should have the form "startDate:asc,firstname:desc"
       */
      orderBy?: Array<string>;

      /**
       * The "where" parameter should have the form "startDate:gte:2022-12-31,endDate:lt:2023-12-31"
       */
      where?: Array<string>;
    },
    context?: HttpContext,
  ): Observable<Array<EventEntity>> {
    return this.eventsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EventEntity>>) => r.body as Array<EventEntity>),
    );
  }

  /**
   * Path part for operation eventsFindOne
   */
  static readonly EventsFindOnePath = '/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<EventEntity> {
    return this.eventsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>) => r.body as EventEntity),
    );
  }

  /**
   * Path part for operation eventsRemove
   */
  static readonly EventsRemovePath = '/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsRemove$Response(
    params: {
      id: string;
      body: DeleteEventDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsRemove(
    params: {
      id: string;
      body: DeleteEventDto;
    },
    context?: HttpContext,
  ): Observable<EventEntity> {
    return this.eventsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>) => r.body as EventEntity),
    );
  }

  /**
   * Path part for operation eventsUpdate
   */
  static readonly EventsUpdatePath = '/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsUpdate$Response(
    params: {
      id: string;
      body: UpdateEventDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsUpdate(
    params: {
      id: string;
      body: UpdateEventDto;
    },
    context?: HttpContext,
  ): Observable<EventEntity> {
    return this.eventsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>) => r.body as EventEntity),
    );
  }

  /**
   * Path part for operation eventsFindRegistrations
   */
  static readonly EventsFindRegistrationsPath = '/events/{id}/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFindRegistrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindRegistrations$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsFindRegistrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFindRegistrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindRegistrations(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationEntity>> {
    return this.eventsFindRegistrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationEntity>>) => r.body as Array<RegistrationEntity>),
    );
  }

  /**
   * Path part for operation eventsCancel
   */
  static readonly EventsCancelPath = '/events/{id}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsCancel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsCancel$Response(
    params: {
      id: string;
      body: CancelEventDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsCancelPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{}>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsCancel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsCancel(
    params: {
      id: string;
      body: CancelEventDto;
    },
    context?: HttpContext,
  ): Observable<{}> {
    return this.eventsCancel$Response(params, context).pipe(map((r: StrictHttpResponse<{}>) => r.body as {}));
  }

  /**
   * Path part for operation eventsFindWeekly
   */
  static readonly EventsFindWeeklyPath = '/events/weekly/{offset}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsFindWeekly()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindWeekly$Response(
    params: {
      offset: number;
      services?: Array<string>;
      staffMembers?: Array<string>;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<EventEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, EventsService.EventsFindWeeklyPath, 'get');
    if (params) {
      rb.path('offset', params.offset, {});
      rb.query('services', params.services, {});
      rb.query('staffMembers', params.staffMembers, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EventEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsFindWeekly$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsFindWeekly(
    params: {
      offset: number;
      services?: Array<string>;
      staffMembers?: Array<string>;
    },
    context?: HttpContext,
  ): Observable<Array<EventEntity>> {
    return this.eventsFindWeekly$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EventEntity>>) => r.body as Array<EventEntity>),
    );
  }
}
