import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from './api/api-configuration';
import { ApiInterceptor } from './api.interceptor';
import * as ApiServices from './api/services';
import * as ApiModels from './api/models';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ApiService {
  account = {
    astadala: () => this.accountsService.accountsAstadala(),
    login: (args: { body: ApiModels.LoginDto }) => this.accountsService.accountsLogin(args),
    logout: () => this.accountsService.accountsLogout(),
    claim: (args: { body: ApiModels.ClaimAccountDto }) => this.accountsService.accountsClaimAccount(args),
    signupInitiate: (args: { body: ApiModels.SignupInitiateDto }) => this.accountsService.accountsSignupInitiate(args),
    signupComplete: (args: { body: ApiModels.SignupCompleteDto }) => this.accountsService.accountsSignupComplete(args),
    passwordResetInitiate: (args: { body: ApiModels.PasswordResetInitiateDto }) =>
      this.accountsService.accountsPasswordResetInitiate(args),
    passwordResetComplete: (args: { body: ApiModels.PasswordResetCompleteDto }) =>
      this.accountsService.accountsPasswordResetComplete(args),
    changeEmailInitiate: (args: { body: ApiModels.ChangeEmailInitiateDto }) =>
      this.accountsService.accountsChangeEmailInitiate(args),
    changeEmailComplete: (args: { body: ApiModels.ChangeEmailCompleteDto }) =>
      this.accountsService.accountsChangeEmailComplete(args),
  };

  cart = {
    view: (args: { id: string }) => this.cartsService.cartsFindOne(args),
    addPass: (args: { id: string; body: ApiModels.CreatePassForUserDto }) => this.cartsService.cartsAddPass(args),
    deletePass: (args: { id: string; passId: string }) => this.cartsService.cartsRemovePass(args),
    deleteAllPasses: (args: { id: string }) => this.cartsService.cartsRemoveAllPasses(args),
    checkout: (args: { id: string; body: ApiModels.CheckoutCartDto }) => this.cartsService.cartsCheckout(args),
  };

  currency = {
    create: (args: { body: ApiModels.CreateCurrencyDto }) => this.currenciesService.currenciesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateCurrencyDto }) => this.currenciesService.currenciesUpdate(args),
    remove: (args: { id: string }) => this.currenciesService.currenciesRemove(args),
    view: (args: { id: string }) => this.currenciesService.currenciesFindOne(args),
    list: () => this.currenciesService.currenciesFindAll(),
  };

  emailTemplate = {
    create: (args: { body: ApiModels.CreateEmailTemplateDto }) => this.emailTemplatesService.emailTemplatesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateEmailTemplateDto }) =>
      this.emailTemplatesService.emailTemplatesUpdate(args),
    remove: (args: { id: string }) => this.emailTemplatesService.emailTemplatesRemove(args),
    view: (args: { id: string }) => this.emailTemplatesService.emailTemplatesFindOne(args),
    list: () => this.emailTemplatesService.emailTemplatesFindAll(),
  };

  event = {
    modify: (args: { id: string; body: ApiModels.UpdateEventDto }) => this.eventsService.eventsUpdate(args),
    cancel: (args: { id: string; body: ApiModels.CancelEventDto }) => this.eventsService.eventsCancel(args),
    remove: (args: { id: string; body: ApiModels.DeleteEventDto }) => this.eventsService.eventsRemove(args),
    view: (args: { id: string }) => this.eventsService.eventsFindOne(args),
    list: () => this.eventsService.eventsFindAll(),
    weekly: (args: { offset: number; services: string[]; staffMembers: string[] }) =>
      this.eventsService.eventsFindWeekly(args),
    registrations: (eventId: string) => this.eventsService.eventsFindRegistrations({ id: eventId }),
  };

  serviceCategory = {
    create: (args: { body: ApiModels.CreateServiceCategoryDto }) =>
      this.serviceCategoriesService.serviceCategoriesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateServiceCategoryDto }) =>
      this.serviceCategoriesService.serviceCategoriesUpdate(args),
    remove: (args: { id: string }) => this.serviceCategoriesService.serviceCategoriesRemove(args),
    view: (args: { id: string }) => this.serviceCategoriesService.serviceCategoriesFindOne(args),
    list: () => this.serviceCategoriesService.serviceCategoriesFindAll(),
  };

  service = {
    create: (args: { body: ApiModels.CreateServiceDto }) => this.servicesService.servicesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateServiceDto }) => this.servicesService.servicesUpdate(args),
    remove: (args: { id: string }) => this.servicesService.servicesRemove(args),
    view: (args: { id: string }) => this.servicesService.servicesFindOne(args),
    list: () => this.servicesService.servicesFindAll(),

    registrationRules: (args: { id: string }) => this.servicesService.servicesFindRegistrationRules(args),
  };

  eventSequence = {
    create: (args: { body: ApiModels.CreateEventSequenceDto }) => this.eventSequencesService.eventSequencesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateEventSequenceDto }) =>
      this.eventSequencesService.eventSequencesUpdate(args),
    remove: (args: { id: string }) => this.eventSequencesService.eventSequencesRemove(args),
    view: (args: { id: string }) => this.eventSequencesService.eventSequencesFindOne(args),
    list: () => this.eventSequencesService.eventSequencesFindAll(),
  };

  gender = {
    create: (args: { body: ApiModels.CreateGenderDto }) => this.gendersService.gendersCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateGenderDto }) => this.gendersService.gendersUpdate(args),
    remove: (args: { id: string }) => this.gendersService.gendersRemove(args),
    view: (args: { id: string }) => this.gendersService.gendersFindOne(args),
    list: () => this.gendersService.gendersFindAll(),
  };

  image = {
    create: (args: { body: ApiModels.CreateImageDto }) => this.imagesService.imagesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateImageDto }) => this.imagesService.imagesUpdate(args),
    remove: (args: { id: string }) => this.imagesService.imagesRemove(args),
    view: (args: { id: string }) => this.imagesService.imagesFindOne(args),
    list: () => this.imagesService.imagesFindAll(),
  };

  invoice = {
    view: (args: { id: string }) => this.invoicesService.invoicesFindOne(args),
    list: () => this.invoicesService.invoicesFindAll(),
  };

  location = {
    create: (args: { body: ApiModels.CreateLocationDto }) => this.locationsService.locationsCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateLocationDto }) => this.locationsService.locationsUpdate(args),
    remove: (args: { id: string }) => this.locationsService.locationsRemove(args),
    view: (args: { id: string }) => this.locationsService.locationsFindOne(args),
    list: () => this.locationsService.locationsFindAll(),
  };

  pass = {
    modify: (args: { id: string; body: ApiModels.UpdatePassDto }) => this.passesService.passesUpdate(args),
    remove: (args: { id: string }) => this.passesService.passesRemove(args),
    view: (args: { id: string }) => this.passesService.passesFindOne(args),
    punches: (args: { id: string }) => this.passesService.passesFindPunches(args),
    list: () => this.passesService.passesFindAll(),
  };

  passCategory = {
    create: (args: { body: ApiModels.CreatePassCategoryDto }) => this.passCategoriesService.passCategoriesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdatePassCategoryDto }) =>
      this.passCategoriesService.passCategoriesUpdate(args),
    reorder: (args: { body: { ids: string[] } }) => this.passCategoriesService.passCategoriesReorder(args),
    remove: (args: { id: string }) => this.passCategoriesService.passCategoriesRemove(args),
    view: (args: { id: string }) => this.passCategoriesService.passCategoriesFindOne(args),
    list: () => this.passCategoriesService.passCategoriesFindAll(),
  };

  passType = {
    create: (args: { body: ApiModels.CreatePassTypeDto }) => this.passTypesService.passTypesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdatePassTypeDto }) => this.passTypesService.passTypesUpdate(args),
    remove: (args: { id: string }) => this.passTypesService.passTypesRemove(args),
    view: (args: { id: string }) => this.passTypesService.passTypesFindOne(args),
    list: () => this.passTypesService.passTypesFindAll(),

    registrationRules: (args: { id: string }) => this.passTypesService.passTypesFindRegistrationRules(args),
  };

  payment = {
    view: (args: { id: string }) => this.paymentsService.paymentsFindOne(args),
    list: () => this.paymentsService.paymentsFindAll(),
  };

  paymentMethod = {
    create: (args: { body: ApiModels.CreatePaymentMethodDto }) => this.paymentMethodsService.paymentMethodsCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdatePaymentMethodDto }) =>
      this.paymentMethodsService.paymentMethodsUpdate(args),
    remove: (args: { id: string }) => this.paymentMethodsService.paymentMethodsRemove(args),
    view: (args: { id: string }) => this.paymentMethodsService.paymentMethodsFindOne(args),
    list: () => this.paymentMethodsService.paymentMethodsFindAll(),
  };

  permission = {
    levels: () => this.permissionsService.permissionsFindLevels(),
    modify: (args: { id: string; body: ApiModels.UpdatePermissionDto }) =>
      this.permissionsService.permissionsUpdate(args),
  };

  registration = {
    create: (args: { body: ApiModels.CreateRegistrationDto }) => this.registrationsService.registrationsCreate(args),
    createWithPunch: (args: { body: ApiModels.CreateRegistrationDto }) =>
      this.registrationsService.registrationsCreateWithPunch(args),
    createPunch: (args: { id: string; body: ApiModels.CreatePunchDto }) =>
      this.registrationsService.registrationsCreatePunch(args),
    modify: (args: { id: string; body: ApiModels.UpdateRegistrationDto }) =>
      this.registrationsService.registrationsUpdate(args),
    remove: (args: { id: string }) => this.registrationsService.registrationsRemove(args),
    removePunch: (args: { id: string }) => this.registrationsService.registrationsRemovePunch(args),
    view: (args: { id: string }) => this.registrationsService.registrationsFindOne(args),
    list: () => this.registrationsService.registrationsFindAll(),
    validPasses: (args: { id: string }) => this.registrationsService.registrationsFindValidPasses(args),
  };

  registrationRule = {
    create: (args: { body: ApiModels.CreateRegistrationRuleDto }) =>
      this.registrationRulesService.registrationRulesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateRegistrationRuleDto }) =>
      this.registrationRulesService.registrationRulesUpdate(args),
    modifyMany: (args: { body: ApiModels.UpdateRegistrationRulesDto }) =>
      this.registrationRulesService.registrationRulesUpdateMany(args),
    remove: (args: { id: string }) => this.registrationRulesService.registrationRulesRemove(args),
    view: (args: { id: string }) => this.registrationRulesService.registrationRulesFindOne(args),
    list: () => this.registrationRulesService.registrationRulesFindAll(),
  };

  room = {
    create: (args: { body: ApiModels.CreateRoomDto }) => this.roomsService.roomsCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateRoomDto }) => this.roomsService.roomsUpdate(args),
    remove: (args: { id: string }) => this.roomsService.roomsRemove(args),
    view: (args: { id: string }) => this.roomsService.roomsFindOne(args),
    list: () => this.roomsService.roomsFindAll(),
  };

  staff = {
    create: (args: { body: ApiModels.CreateStaffDto }) => this.staffService.staffCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateStaffDto }) => this.staffService.staffUpdate(args),
    remove: (args: { id: string }) => this.staffService.staffRemove(args),
    view: (id: string) => this.staffService.staffFindOne({ id }),
    list: () => this.staffService.staffFindAll(),
    events: (id: string) => this.staffService.staffFindEvents({ id }),
  };

  staffRole = {
    create: (args: { body: ApiModels.CreateStaffRoleDto }) => this.staffRolesService.staffRolesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateStaffRoleDto }) => this.staffRolesService.staffRolesUpdate(args),
    remove: (args: { id: string }) => this.staffRolesService.staffRolesRemove(args),
    view: (args: { id: string }) => this.staffRolesService.staffRolesFindOne(args),
    list: () => this.staffRolesService.staffRolesFindAll(),
  };

  setting = {
    create: (args: { body: ApiModels.CreateSettingDto }) => this.settingsService.settingsCreate(args),
    modify: (args: { category: string; key: string; body: ApiModels.UpdateSettingDto }) =>
      this.settingsService.settingsUpdate(args),
    remove: (args: { category: string; key: string }) => this.settingsService.settingsRemove(args),
    view: (args: { category: string; key: string }) => this.settingsService.settingsFindOne(args),
    list: (args: { category: string }) => this.settingsService.settingsFindAll(args),
  };

  stripe = {
    initiatePaymentIntent: (args: { body: ApiModels.InitiatePaymentIntentDto }) =>
      this.pluginsStripeServide.stripeInitiatePaymentIntent(args),
  };

  tax = {
    create: (args: { body: ApiModels.CreateTaxDto }) => this.taxesService.taxesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateTaxDto }) => this.taxesService.taxesUpdate(args),
    remove: (args: { id: string }) => this.taxesService.taxesRemove(args),
    view: (args: { id: string }) => this.taxesService.taxesFindOne(args),
    list: () => this.taxesService.taxesFindAll(),
  };

  timezone = {
    create: (args: { body: ApiModels.CreateTimezoneDto }) => this.timezonesService.timezonesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateTimezoneDto }) => this.timezonesService.timezonesUpdate(args),
    remove: (args: { id: string }) => this.timezonesService.timezonesRemove(args),
    view: (args: { id: string }) => this.timezonesService.timezonesFindOne(args),
    list: () => this.timezonesService.timezonesFindAll(),
  };

  user = {
    create: (args: { body: ApiModels.CreateUserDto }) => this.usersService.usersCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateUserDto }) => this.usersService.usersUpdate(args),
    modifyProfile: (args: { id: string; body: ApiModels.UpdateUserProfileDto }) =>
      this.usersService.usersUpdateProfile(args),
    changePassword: (args: { id: string; body: ApiModels.ChangePasswordDto }) =>
      this.usersService.usersChangePassword(args),
    view: (id: string) => this.usersService.usersFindOne({ id: id === 'me' ? 'me' : id }),
    list: (args?: { q?: string; after?: string; take?: number; registrationsForEventId?: string }) =>
      this.usersService.usersFindAll(args),
    count: () => this.usersService.usersCount(),
    sessions: (userId: string) => this.usersService.usersFindUserSessions({ id: userId === 'me' ? 'me' : userId }),
    registration: (userId: string, eventId: string) =>
      this.usersService.usersFindRegistrationForEvent({ id: userId, eventId }),
    registrations: (args: { id: string; from: string; to: string; sort: 'asc' | 'desc' }) =>
      this.usersService.usersFindRegistrations(args),
    registrationsForWeek: (userId: string, offset: number) =>
      this.usersService.usersFindRegistrationsForWeek({ id: userId, offset }),
    passes: (userId: string) => this.usersService.usersFindPasses({ id: userId === 'me' ? 'me' : userId }),
    invoices: (userId: string) => this.usersService.usersFindInvoices({ id: userId === 'me' ? 'me' : userId }),
    currentCart: (userId: string) => this.usersService.usersFindCurrentCart({ id: userId === 'me' ? 'me' : userId }),
  };

  userRole = {
    create: (args: { body: ApiModels.CreateUserRoleDto }) => this.userRolesService.userRolesCreate(args),
    modify: (args: { id: string; body: ApiModels.UpdateUserRoleDto }) => this.userRolesService.userRolesUpdate(args),
    remove: (args: { id: string }) => this.userRolesService.userRolesRemove(args),
    view: (args: { id: string }) => this.userRolesService.userRolesFindOne(args),
    permissions: (args: { id: string }) => this.userRolesService.userRolesFindPermissions(args),
    list: () => this.userRolesService.userRolesFindAll(),
  };

  userSession = {
    modify: (args: { id: string; body: ApiModels.UpdateUserSessionDto }) =>
      this.userSessionsService.userSessionsUpdate(args),
    remove: (args: { id: string }) => this.userSessionsService.userSessionsRemove(args),
    view: (args: { id: string }) => this.userSessionsService.userSessionsFindOne(args),
    list: () => this.userSessionsService.userSessionsFindAll(),
  };

  private loggedInUser: ApiModels.UserEntity | undefined;

  constructor(
    protected http: HttpClient,
    protected apiConfiguration: ApiConfiguration,
    protected apiInterceptor: ApiInterceptor,
    private accountsService: ApiServices.AccountsService,
    private cartsService: ApiServices.CartsService,
    private currenciesService: ApiServices.CurrenciesService,
    private emailTemplatesService: ApiServices.EmailTemplatesService,
    private eventsService: ApiServices.EventsService,
    private serviceCategoriesService: ApiServices.ServiceCategoriesService,
    private servicesService: ApiServices.ServicesService,
    private eventSequencesService: ApiServices.EventSequencesService,
    private gendersService: ApiServices.GendersService,
    private imagesService: ApiServices.ImagesService,
    private invoicesService: ApiServices.InvoicesService,
    private locationsService: ApiServices.LocationsService,
    private passCategoriesService: ApiServices.PassCategoriesService,
    private passesService: ApiServices.PassesService,
    private passTypesService: ApiServices.PassTypesService,
    private paymentsService: ApiServices.PaymentsService,
    private paymentMethodsService: ApiServices.PaymentMethodsService,
    private permissionsService: ApiServices.PermissionsService,
    private pluginsStripeServide: ApiServices.PluginsStripeService,
    private registrationsService: ApiServices.RegistrationsService,
    private registrationRulesService: ApiServices.RegistrationRulesService,
    private roomsService: ApiServices.RoomsService,
    private staffService: ApiServices.StaffService,
    private staffRolesService: ApiServices.StaffRolesService,
    private settingsService: ApiServices.SettingsService,
    private userSessionsService: ApiServices.UserSessionsService,
    private taxesService: ApiServices.TaxesService,
    private timezonesService: ApiServices.TimezonesService,
    private userRolesService: ApiServices.UserRolesService,
    private usersService: ApiServices.UsersService,
  ) {
    this.loggedInUser = undefined;
  }

  getRootUrl(): string {
    return this.apiConfiguration.rootUrl;
  }

  setRootUrl(rootUrl: string) {
    this.apiConfiguration.rootUrl = rootUrl;
  }

  setToken(token: string) {
    this.apiInterceptor.setToken(token);
  }

  async setLoggedInUser() {
    const user = await firstValueFrom(this.user.view('me'));

    this.loggedInUser = user;
    return user;
  }

  unsetLoggedInUser() {
    this.loggedInUser = undefined;
  }

  getLoggedInUser(): ApiModels.UserEntity {
    return this.loggedInUser;
  }
}
