/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateStaffDto } from '../models/create-staff-dto';
import { EventEntity } from '../models/event-entity';
import { UpdateStaffDto } from '../models/update-staff-dto';
import { UserEntity } from '../models/user-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation staffFindAll
   */
  static readonly StaffFindAllPath = '/staff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<UserEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindAll(params?: {}, context?: HttpContext): Observable<Array<UserEntity>> {
    return this.staffFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserEntity>>) => r.body as Array<UserEntity>),
    );
  }

  /**
   * Path part for operation staffCreate
   */
  static readonly StaffCreatePath = '/staff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffCreate$Response(
    params: {
      body: CreateStaffDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffCreate(
    params: {
      body: CreateStaffDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.staffCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation staffFindOne
   */
  static readonly StaffFindOnePath = '/staff/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.staffFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation staffRemove
   */
  static readonly StaffRemovePath = '/staff/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.staffRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation staffUpdate
   */
  static readonly StaffUpdatePath = '/staff/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffUpdate$Response(
    params: {
      id: string;
      body: UpdateStaffDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffUpdate(
    params: {
      id: string;
      body: UpdateStaffDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.staffUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation staffFindEvents
   */
  static readonly StaffFindEventsPath = '/staff/{id}/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffFindEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindEvents$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<EventEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, StaffService.StaffFindEventsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EventEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffFindEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffFindEvents(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<EventEntity>> {
    return this.staffFindEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EventEntity>>) => r.body as Array<EventEntity>),
    );
  }
}
