/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateServiceCategoryDto } from '../models/create-service-category-dto';
import { ServiceCategoryEntity } from '../models/service-category-entity';
import { UpdateServiceCategoryDto } from '../models/update-service-category-dto';

@Injectable({
  providedIn: 'root',
})
export class ServiceCategoriesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation serviceCategoriesFindAll
   */
  static readonly ServiceCategoriesFindAllPath = '/service-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceCategoriesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ServiceCategoryEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, ServiceCategoriesService.ServiceCategoriesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ServiceCategoryEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceCategoriesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesFindAll(params?: {}, context?: HttpContext): Observable<Array<ServiceCategoryEntity>> {
    return this.serviceCategoriesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ServiceCategoryEntity>>) => r.body as Array<ServiceCategoryEntity>),
    );
  }

  /**
   * Path part for operation serviceCategoriesCreate
   */
  static readonly ServiceCategoriesCreatePath = '/service-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceCategoriesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceCategoriesCreate$Response(
    params: {
      body: CreateServiceCategoryDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServiceCategoriesService.ServiceCategoriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceCategoriesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceCategoriesCreate(
    params: {
      body: CreateServiceCategoryDto;
    },
    context?: HttpContext,
  ): Observable<ServiceCategoryEntity> {
    return this.serviceCategoriesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceCategoryEntity>) => r.body as ServiceCategoryEntity),
    );
  }

  /**
   * Path part for operation serviceCategoriesFindOne
   */
  static readonly ServiceCategoriesFindOnePath = '/service-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceCategoriesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServiceCategoriesService.ServiceCategoriesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceCategoriesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<ServiceCategoryEntity> {
    return this.serviceCategoriesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceCategoryEntity>) => r.body as ServiceCategoryEntity),
    );
  }

  /**
   * Path part for operation serviceCategoriesRemove
   */
  static readonly ServiceCategoriesRemovePath = '/service-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceCategoriesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServiceCategoriesService.ServiceCategoriesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceCategoriesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceCategoriesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<ServiceCategoryEntity> {
    return this.serviceCategoriesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceCategoryEntity>) => r.body as ServiceCategoryEntity),
    );
  }

  /**
   * Path part for operation serviceCategoriesUpdate
   */
  static readonly ServiceCategoriesUpdatePath = '/service-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceCategoriesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceCategoriesUpdate$Response(
    params: {
      id: string;
      body: UpdateServiceCategoryDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServiceCategoriesService.ServiceCategoriesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceCategoriesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceCategoriesUpdate(
    params: {
      id: string;
      body: UpdateServiceCategoryDto;
    },
    context?: HttpContext,
  ): Observable<ServiceCategoryEntity> {
    return this.serviceCategoriesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceCategoryEntity>) => r.body as ServiceCategoryEntity),
    );
  }
}
