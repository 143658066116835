/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AstadalaEntity } from '../models/astadala-entity';
import { AuthResponseEntity } from '../models/auth-response-entity';
import { ChangeEmailCompleteDto } from '../models/change-email-complete-dto';
import { ChangeEmailInitiateDto } from '../models/change-email-initiate-dto';
import { ClaimAccountDto } from '../models/claim-account-dto';
import { LoginDto } from '../models/login-dto';
import { LoginEntity } from '../models/login-entity';
import { PasswordResetCompleteDto } from '../models/password-reset-complete-dto';
import { PasswordResetInitiateDto } from '../models/password-reset-initiate-dto';
import { SignupCompleteDto } from '../models/signup-complete-dto';
import { SignupInitiateDto } from '../models/signup-initiate-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation accountsAstadala
   */
  static readonly AccountsAstadalaPath = '/accounts/astadala';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsAstadala()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsAstadala$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<AstadalaEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsAstadalaPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AstadalaEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsAstadala$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsAstadala(params?: {}, context?: HttpContext): Observable<AstadalaEntity> {
    return this.accountsAstadala$Response(params, context).pipe(
      map((r: StrictHttpResponse<AstadalaEntity>) => r.body as AstadalaEntity),
    );
  }

  /**
   * Path part for operation accountsLogin
   */
  static readonly AccountsLoginPath = '/accounts/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsLogin$Response(
    params: {
      body: LoginDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<LoginEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LoginEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsLogin(
    params: {
      body: LoginDto;
    },
    context?: HttpContext,
  ): Observable<LoginEntity> {
    return this.accountsLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginEntity>) => r.body as LoginEntity),
    );
  }

  /**
   * Path part for operation accountsLogout
   */
  static readonly AccountsLogoutPath = '/accounts/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsLogout$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsLogoutPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsLogout(params?: {}, context?: HttpContext): Observable<AuthResponseEntity> {
    return this.accountsLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }

  /**
   * Path part for operation accountsClaimAccount
   */
  static readonly AccountsClaimAccountPath = '/accounts/claim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsClaimAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsClaimAccount$Response(
    params: {
      body: ClaimAccountDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<LoginEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsClaimAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LoginEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsClaimAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsClaimAccount(
    params: {
      body: ClaimAccountDto;
    },
    context?: HttpContext,
  ): Observable<LoginEntity> {
    return this.accountsClaimAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginEntity>) => r.body as LoginEntity),
    );
  }

  /**
   * Path part for operation accountsSignupInitiate
   */
  static readonly AccountsSignupInitiatePath = '/accounts/signup/initiate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsSignupInitiate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsSignupInitiate$Response(
    params: {
      body: SignupInitiateDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsSignupInitiatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsSignupInitiate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsSignupInitiate(
    params: {
      body: SignupInitiateDto;
    },
    context?: HttpContext,
  ): Observable<AuthResponseEntity> {
    return this.accountsSignupInitiate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }

  /**
   * Path part for operation accountsSignupComplete
   */
  static readonly AccountsSignupCompletePath = '/accounts/signup/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsSignupComplete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsSignupComplete$Response(
    params: {
      body: SignupCompleteDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<LoginEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsSignupCompletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LoginEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsSignupComplete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsSignupComplete(
    params: {
      body: SignupCompleteDto;
    },
    context?: HttpContext,
  ): Observable<LoginEntity> {
    return this.accountsSignupComplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginEntity>) => r.body as LoginEntity),
    );
  }

  /**
   * Path part for operation accountsPasswordResetInitiate
   */
  static readonly AccountsPasswordResetInitiatePath = '/accounts/password-reset/initiate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsPasswordResetInitiate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsPasswordResetInitiate$Response(
    params: {
      body: PasswordResetInitiateDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsPasswordResetInitiatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsPasswordResetInitiate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsPasswordResetInitiate(
    params: {
      body: PasswordResetInitiateDto;
    },
    context?: HttpContext,
  ): Observable<AuthResponseEntity> {
    return this.accountsPasswordResetInitiate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }

  /**
   * Path part for operation accountsPasswordResetComplete
   */
  static readonly AccountsPasswordResetCompletePath = '/accounts/password-reset/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsPasswordResetComplete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsPasswordResetComplete$Response(
    params: {
      body: PasswordResetCompleteDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsPasswordResetCompletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsPasswordResetComplete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsPasswordResetComplete(
    params: {
      body: PasswordResetCompleteDto;
    },
    context?: HttpContext,
  ): Observable<AuthResponseEntity> {
    return this.accountsPasswordResetComplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }

  /**
   * Path part for operation accountsChangeEmailInitiate
   */
  static readonly AccountsChangeEmailInitiatePath = '/accounts/change-email/initiate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsChangeEmailInitiate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsChangeEmailInitiate$Response(
    params: {
      body: ChangeEmailInitiateDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsChangeEmailInitiatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsChangeEmailInitiate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsChangeEmailInitiate(
    params: {
      body: ChangeEmailInitiateDto;
    },
    context?: HttpContext,
  ): Observable<AuthResponseEntity> {
    return this.accountsChangeEmailInitiate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }

  /**
   * Path part for operation accountsChangeEmailComplete
   */
  static readonly AccountsChangeEmailCompletePath = '/accounts/change-email/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsChangeEmailComplete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsChangeEmailComplete$Response(
    params: {
      body: ChangeEmailCompleteDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AuthResponseEntity>> {
    const rb = new RequestBuilder(this.rootUrl, AccountsService.AccountsChangeEmailCompletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsChangeEmailComplete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountsChangeEmailComplete(
    params: {
      body: ChangeEmailCompleteDto;
    },
    context?: HttpContext,
  ): Observable<AuthResponseEntity> {
    return this.accountsChangeEmailComplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthResponseEntity>) => r.body as AuthResponseEntity),
    );
  }
}
