/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CartEntity } from '../models/cart-entity';
import { CheckoutCartDto } from '../models/checkout-cart-dto';
import { CreatePassForUserDto } from '../models/create-pass-for-user-dto';
import { InvoiceEntity } from '../models/invoice-entity';
import { PassEntity } from '../models/pass-entity';

@Injectable({
  providedIn: 'root',
})
export class CartsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation cartsFindAll
   */
  static readonly CartsFindAllPath = '/carts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<{}>>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<{}>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsFindAll(params?: {}, context?: HttpContext): Observable<Array<{}>> {
    return this.cartsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{}>>) => r.body as Array<{}>),
    );
  }

  /**
   * Path part for operation cartsFindOne
   */
  static readonly CartsFindOnePath = '/carts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<CartEntity>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CartEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<CartEntity> {
    return this.cartsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartEntity>) => r.body as CartEntity),
    );
  }

  /**
   * Path part for operation cartsAddPass
   */
  static readonly CartsAddPassPath = '/carts/{id}/passes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsAddPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cartsAddPass$Response(
    params: {
      id: string;
      body: CreatePassForUserDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassEntity>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsAddPassPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsAddPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cartsAddPass(
    params: {
      id: string;
      body: CreatePassForUserDto;
    },
    context?: HttpContext,
  ): Observable<PassEntity> {
    return this.cartsAddPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassEntity>) => r.body as PassEntity),
    );
  }

  /**
   * Path part for operation cartsRemoveAllPasses
   */
  static readonly CartsRemoveAllPassesPath = '/carts/{id}/passes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsRemoveAllPasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsRemoveAllPasses$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<CartEntity>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsRemoveAllPassesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CartEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsRemoveAllPasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsRemoveAllPasses(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<CartEntity> {
    return this.cartsRemoveAllPasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartEntity>) => r.body as CartEntity),
    );
  }

  /**
   * Path part for operation cartsCheckout
   */
  static readonly CartsCheckoutPath = '/carts/{id}/checkout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsCheckout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cartsCheckout$Response(
    params: {
      id: string;
      body: CheckoutCartDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InvoiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsCheckoutPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<InvoiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsCheckout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cartsCheckout(
    params: {
      id: string;
      body: CheckoutCartDto;
    },
    context?: HttpContext,
  ): Observable<InvoiceEntity> {
    return this.cartsCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceEntity>) => r.body as InvoiceEntity),
    );
  }

  /**
   * Path part for operation cartsRemovePass
   */
  static readonly CartsRemovePassPath = '/carts/{id}/passes/{passId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartsRemovePass()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsRemovePass$Response(
    params: {
      id: string;
      passId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassEntity>> {
    const rb = new RequestBuilder(this.rootUrl, CartsService.CartsRemovePassPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('passId', params.passId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartsRemovePass$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartsRemovePass(
    params: {
      id: string;
      passId: string;
    },
    context?: HttpContext,
  ): Observable<PassEntity> {
    return this.cartsRemovePass$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassEntity>) => r.body as PassEntity),
    );
  }
}
