import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { AppEventService } from './app-event.service';

export interface ScheduleFilter {
  active?: boolean;
  services?: string[];
  staffMembers?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  prefix = 'aca-';

  constructor(public appEvent: AppEventService) {}

  login = async (sessionId: string, userId: string) => {
    await Promise.all([
      Preferences.set({ key: this.prefix + 'sessionId', value: sessionId }),
      Preferences.set({ key: this.prefix + 'userId', value: userId }),
    ]);
    this.appEvent.emit('user:login', sessionId);
  };

  logout = async () => {
    await Promise.all([
      Preferences.remove({ key: this.prefix + 'sessionId' }),
      Preferences.remove({ key: this.prefix + 'userId' }),
    ]);
    this.appEvent.emit('user:logout');
  };

  getSessionId = async () => {
    const token = await Preferences.get({ key: this.prefix + 'sessionId' });
    return token.value;
  };

  getUserId = async () => {
    const user = await Preferences.get({ key: this.prefix + 'userId' });
    return user.value;
  };

  setServer = async (server: string) => {
    await Preferences.set({ key: this.prefix + 'server', value: server });
  };

  getServer = async () => {
    const server = await Preferences.get({ key: this.prefix + 'server' });
    return server.value;
  };

  setTheme = async (theme) => {
    await Preferences.set({ key: this.prefix + 'theme', value: theme });
  };

  getTheme = async () => {
    const theme = await Preferences.get({ key: this.prefix + 'theme' });
    return theme.value;
  };

  setLanguage = async (language) => {
    await Preferences.set({ key: this.prefix + 'language', value: language });
  };

  getLanguage = async () => {
    const language = await Preferences.get({ key: this.prefix + 'language' });
    return language.value;
  };

  setScheduleFilter = async (filter: ScheduleFilter) => {
    await Preferences.set({ key: this.prefix + 'schedulefilter', value: JSON.stringify(filter) });
    this.appEvent.emit('scheduleFilter:changed', filter);
  };

  getScheduleFilter = async () => {
    const filter = await Preferences.get({ key: this.prefix + 'schedulefilter' });

    if (!filter.value) {
      await Preferences.set({ key: this.prefix + 'schedulefilter', value: JSON.stringify({}) });
      return {} as ScheduleFilter;
    }

    return JSON.parse(filter.value) as ScheduleFilter;
  };
}
