/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CartEntity } from '../models/cart-entity';
import { ChangePasswordDto } from '../models/change-password-dto';
import { CreateUserDto } from '../models/create-user-dto';
import { InvoiceEntity } from '../models/invoice-entity';
import { PassEntity } from '../models/pass-entity';
import { RegistrationEntity } from '../models/registration-entity';
import { UpdateRolesForUserDto } from '../models/update-roles-for-user-dto';
import { UpdateUserDto } from '../models/update-user-dto';
import { UpdateUserProfileDto } from '../models/update-user-profile-dto';
import { UserEntity } from '../models/user-entity';
import { UserSessionEntity } from '../models/user-session-entity';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation usersFindAll
   */
  static readonly UsersFindAllPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindAll$Response(
    params?: {
      /**
       * The "orderBy" parameter should have the form "startDate:asc,firstname:desc"
       */
      orderBy?: Array<string>;

      /**
       * The "where" parameter should have the form "startDate:gte:2022-12-31,endDate:lt:2023-12-31"
       */
      where?: Array<string>;
      q?: string;
      take?: number;
      after?: string;
      showInactive?: boolean;
      includeRegistrationsForEventId?: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<UserEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindAllPath, 'get');
    if (params) {
      rb.query('orderBy', params.orderBy, {});
      rb.query('where', params.where, {});
      rb.query('q', params.q, {});
      rb.query('take', params.take, {});
      rb.query('after', params.after, {});
      rb.query('showInactive', params.showInactive, {});
      rb.query('includeRegistrationsForEventId', params.includeRegistrationsForEventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindAll(
    params?: {
      /**
       * The "orderBy" parameter should have the form "startDate:asc,firstname:desc"
       */
      orderBy?: Array<string>;

      /**
       * The "where" parameter should have the form "startDate:gte:2022-12-31,endDate:lt:2023-12-31"
       */
      where?: Array<string>;
      q?: string;
      take?: number;
      after?: string;
      showInactive?: boolean;
      includeRegistrationsForEventId?: string;
    },
    context?: HttpContext,
  ): Observable<Array<UserEntity>> {
    return this.usersFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserEntity>>) => r.body as Array<UserEntity>),
    );
  }

  /**
   * Path part for operation usersCreate
   */
  static readonly UsersCreatePath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersCreate$Response(
    params: {
      body: CreateUserDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersCreate(
    params: {
      body: CreateUserDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersFindOne
   */
  static readonly UsersFindOnePath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersRemove
   */
  static readonly UsersRemovePath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersUpdate
   */
  static readonly UsersUpdatePath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdate$Response(
    params: {
      id: string;
      body: UpdateUserDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdate(
    params: {
      id: string;
      body: UpdateUserDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersToggleActive
   */
  static readonly UsersToggleActivePath = '/users/{id}/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersToggleActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersToggleActive$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersToggleActivePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersToggleActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersToggleActive(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersToggleActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersUpdateProfile
   */
  static readonly UsersUpdateProfilePath = '/users/{id}/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdateProfile$Response(
    params: {
      id: string;
      body: UpdateUserProfileDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdateProfilePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersUpdateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdateProfile(
    params: {
      id: string;
      body: UpdateUserProfileDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersUpdateProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersUpdateRoles
   */
  static readonly UsersUpdateRolesPath = '/users/{id}/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdateRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdateRoles$Response(
    params: {
      id: string;
      body: UpdateRolesForUserDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdateRolesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersUpdateRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersUpdateRoles(
    params: {
      id: string;
      body: UpdateRolesForUserDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersUpdateRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersChangePassword
   */
  static readonly UsersChangePasswordPath = '/users/{id}/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersChangePassword$Response(
    params: {
      id: string;
      body: ChangePasswordDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersChangePasswordPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersChangePassword(
    params: {
      id: string;
      body: ChangePasswordDto;
    },
    context?: HttpContext,
  ): Observable<UserEntity> {
    return this.usersChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserEntity>) => r.body as UserEntity),
    );
  }

  /**
   * Path part for operation usersCount
   */
  static readonly UsersCountPath = '/users/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersCount$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersCountPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: parseFloat(String((r as HttpResponse<any>).body)),
          }) as StrictHttpResponse<number>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersCount(params?: {}, context?: HttpContext): Observable<number> {
    return this.usersCount$Response(params, context).pipe(map((r: StrictHttpResponse<number>) => r.body as number));
  }

  /**
   * Path part for operation usersFindUserSessions
   */
  static readonly UsersFindUserSessionsPath = '/users/{id}/sessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindUserSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindUserSessions$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<UserSessionEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindUserSessionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserSessionEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindUserSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindUserSessions(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<UserSessionEntity>> {
    return this.usersFindUserSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserSessionEntity>>) => r.body as Array<UserSessionEntity>),
    );
  }

  /**
   * Path part for operation usersFindRegistrations
   */
  static readonly UsersFindRegistrationsPath = '/users/{id}/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindRegistrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrations$Response(
    params: {
      id: string;
      sort?: 'asc' | 'desc';
      from?: string;
      to?: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindRegistrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindRegistrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrations(
    params: {
      id: string;
      sort?: 'asc' | 'desc';
      from?: string;
      to?: string;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationEntity>> {
    return this.usersFindRegistrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationEntity>>) => r.body as Array<RegistrationEntity>),
    );
  }

  /**
   * Path part for operation usersFindRegistrationsForWeek
   */
  static readonly UsersFindRegistrationsForWeekPath = '/users/{id}/registrations/weekly/{offset}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindRegistrationsForWeek()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrationsForWeek$Response(
    params: {
      id: string;
      offset: number;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindRegistrationsForWeekPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offset', params.offset, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindRegistrationsForWeek$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrationsForWeek(
    params: {
      id: string;
      offset: number;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationEntity>> {
    return this.usersFindRegistrationsForWeek$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationEntity>>) => r.body as Array<RegistrationEntity>),
    );
  }

  /**
   * Path part for operation usersFindRegistrationForEvent
   */
  static readonly UsersFindRegistrationForEventPath = '/users/{id}/event/{eventId}/registration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindRegistrationForEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrationForEvent$Response(
    params: {
      id: string;
      eventId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindRegistrationForEventPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('eventId', params.eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindRegistrationForEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindRegistrationForEvent(
    params: {
      id: string;
      eventId: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.usersFindRegistrationForEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation usersFindPasses
   */
  static readonly UsersFindPassesPath = '/users/{id}/passes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindPasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindPasses$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PassEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindPassesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindPasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindPasses(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<PassEntity>> {
    return this.usersFindPasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassEntity>>) => r.body as Array<PassEntity>),
    );
  }

  /**
   * Path part for operation usersFindInvoices
   */
  static readonly UsersFindInvoicesPath = '/users/{id}/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindInvoices$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<InvoiceEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindInvoicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<InvoiceEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindInvoices(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<InvoiceEntity>> {
    return this.usersFindInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceEntity>>) => r.body as Array<InvoiceEntity>),
    );
  }

  /**
   * Path part for operation usersFindCarts
   */
  static readonly UsersFindCartsPath = '/users/{id}/carts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindCarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindCarts$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<CartEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindCartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CartEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindCarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindCarts(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<CartEntity>> {
    return this.usersFindCarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CartEntity>>) => r.body as Array<CartEntity>),
    );
  }

  /**
   * Path part for operation usersFindCurrentCart
   */
  static readonly UsersFindCurrentCartPath = '/users/{id}/carts/current';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersFindCurrentCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindCurrentCart$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<CartEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersFindCurrentCartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CartEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersFindCurrentCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersFindCurrentCart(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<CartEntity> {
    return this.usersFindCurrentCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartEntity>) => r.body as CartEntity),
    );
  }
}
