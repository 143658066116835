/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateServiceDto } from '../models/create-service-dto';
import { RegistrationRuleEntity } from '../models/registration-rule-entity';
import { ServiceEntity } from '../models/service-entity';
import { UpdateServiceDto } from '../models/update-service-dto';

@Injectable({
  providedIn: 'root',
})
export class ServicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation servicesFindAll
   */
  static readonly ServicesFindAllPath = '/services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<ServiceEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ServiceEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindAll(params?: {}, context?: HttpContext): Observable<Array<ServiceEntity>> {
    return this.servicesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ServiceEntity>>) => r.body as Array<ServiceEntity>),
    );
  }

  /**
   * Path part for operation servicesCreate
   */
  static readonly ServicesCreatePath = '/services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  servicesCreate$Response(
    params: {
      body: CreateServiceDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  servicesCreate(
    params: {
      body: CreateServiceDto;
    },
    context?: HttpContext,
  ): Observable<ServiceEntity> {
    return this.servicesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceEntity>) => r.body as ServiceEntity),
    );
  }

  /**
   * Path part for operation servicesFindAllActive
   */
  static readonly ServicesFindAllActivePath = '/services/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesFindAllActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindAllActive$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ServiceEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesFindAllActivePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ServiceEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesFindAllActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindAllActive(params?: {}, context?: HttpContext): Observable<Array<ServiceEntity>> {
    return this.servicesFindAllActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ServiceEntity>>) => r.body as Array<ServiceEntity>),
    );
  }

  /**
   * Path part for operation servicesFindOne
   */
  static readonly ServicesFindOnePath = '/services/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<ServiceEntity> {
    return this.servicesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceEntity>) => r.body as ServiceEntity),
    );
  }

  /**
   * Path part for operation servicesRemove
   */
  static readonly ServicesRemovePath = '/services/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<ServiceEntity> {
    return this.servicesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceEntity>) => r.body as ServiceEntity),
    );
  }

  /**
   * Path part for operation servicesUpdate
   */
  static readonly ServicesUpdatePath = '/services/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  servicesUpdate$Response(
    params: {
      id: string;
      body: UpdateServiceDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ServiceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ServiceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  servicesUpdate(
    params: {
      id: string;
      body: UpdateServiceDto;
    },
    context?: HttpContext,
  ): Observable<ServiceEntity> {
    return this.servicesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceEntity>) => r.body as ServiceEntity),
    );
  }

  /**
   * Path part for operation servicesFindRegistrationRules
   */
  static readonly ServicesFindRegistrationRulesPath = '/services/{id}/registration-rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `servicesFindRegistrationRules()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindRegistrationRules$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationRuleEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, ServicesService.ServicesFindRegistrationRulesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationRuleEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `servicesFindRegistrationRules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  servicesFindRegistrationRules(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationRuleEntity>> {
    return this.servicesFindRegistrationRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationRuleEntity>>) => r.body as Array<RegistrationRuleEntity>),
    );
  }
}
