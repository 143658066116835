/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddEventToEventSequenceDto } from '../models/add-event-to-event-sequence-dto';
import { CreateEventSequenceDto } from '../models/create-event-sequence-dto';
import { EventSequenceEntity } from '../models/event-sequence-entity';
import { UpdateEventSequenceDto } from '../models/update-event-sequence-dto';

@Injectable({
  providedIn: 'root',
})
export class EventSequencesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation eventSequencesFindAll
   */
  static readonly EventSequencesFindAllPath = '/event-sequences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<EventSequenceEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, EventSequencesService.EventSequencesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EventSequenceEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesFindAll(params?: {}, context?: HttpContext): Observable<Array<EventSequenceEntity>> {
    return this.eventSequencesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EventSequenceEntity>>) => r.body as Array<EventSequenceEntity>),
    );
  }

  /**
   * Path part for operation eventSequencesCreate
   */
  static readonly EventSequencesCreatePath = '/event-sequences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesCreate$Response(
    params: {
      body: CreateEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventSequenceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventSequencesService.EventSequencesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventSequenceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesCreate(
    params: {
      body: CreateEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<EventSequenceEntity> {
    return this.eventSequencesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventSequenceEntity>) => r.body as EventSequenceEntity),
    );
  }

  /**
   * Path part for operation eventSequencesAddEventToEventSequence
   */
  static readonly EventSequencesAddEventToEventSequencePath = '/event-sequences/{id}/add-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesAddEventToEventSequence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesAddEventToEventSequence$Response(
    params: {
      id: string;
      body: AddEventToEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventSequenceEntity>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventSequencesService.EventSequencesAddEventToEventSequencePath,
      'post',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventSequenceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesAddEventToEventSequence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesAddEventToEventSequence(
    params: {
      id: string;
      body: AddEventToEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<EventSequenceEntity> {
    return this.eventSequencesAddEventToEventSequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventSequenceEntity>) => r.body as EventSequenceEntity),
    );
  }

  /**
   * Path part for operation eventSequencesFindOne
   */
  static readonly EventSequencesFindOnePath = '/event-sequences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventSequenceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventSequencesService.EventSequencesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventSequenceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<EventSequenceEntity> {
    return this.eventSequencesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventSequenceEntity>) => r.body as EventSequenceEntity),
    );
  }

  /**
   * Path part for operation eventSequencesRemove
   */
  static readonly EventSequencesRemovePath = '/event-sequences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventSequenceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventSequencesService.EventSequencesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventSequenceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSequencesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<EventSequenceEntity> {
    return this.eventSequencesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventSequenceEntity>) => r.body as EventSequenceEntity),
    );
  }

  /**
   * Path part for operation eventSequencesUpdate
   */
  static readonly EventSequencesUpdatePath = '/event-sequences/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSequencesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesUpdate$Response(
    params: {
      id: string;
      body: UpdateEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EventSequenceEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EventSequencesService.EventSequencesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventSequenceEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSequencesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventSequencesUpdate(
    params: {
      id: string;
      body: UpdateEventSequenceDto;
    },
    context?: HttpContext,
  ): Observable<EventSequenceEntity> {
    return this.eventSequencesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventSequenceEntity>) => r.body as EventSequenceEntity),
    );
  }
}
