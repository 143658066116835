/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateEmailTemplateDto } from '../models/create-email-template-dto';
import { EmailTemplateEntity } from '../models/email-template-entity';
import { UpdateEmailTemplateDto } from '../models/update-email-template-dto';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation emailTemplatesFindAll
   */
  static readonly EmailTemplatesFindAllPath = '/email-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailTemplatesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<EmailTemplateEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplatesService.EmailTemplatesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmailTemplateEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailTemplatesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesFindAll(params?: {}, context?: HttpContext): Observable<Array<EmailTemplateEntity>> {
    return this.emailTemplatesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailTemplateEntity>>) => r.body as Array<EmailTemplateEntity>),
    );
  }

  /**
   * Path part for operation emailTemplatesCreate
   */
  static readonly EmailTemplatesCreatePath = '/email-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailTemplatesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailTemplatesCreate$Response(
    params: {
      body: CreateEmailTemplateDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EmailTemplateEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplatesService.EmailTemplatesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmailTemplateEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailTemplatesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailTemplatesCreate(
    params: {
      body: CreateEmailTemplateDto;
    },
    context?: HttpContext,
  ): Observable<EmailTemplateEntity> {
    return this.emailTemplatesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailTemplateEntity>) => r.body as EmailTemplateEntity),
    );
  }

  /**
   * Path part for operation emailTemplatesFindOne
   */
  static readonly EmailTemplatesFindOnePath = '/email-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailTemplatesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EmailTemplateEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplatesService.EmailTemplatesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmailTemplateEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailTemplatesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<EmailTemplateEntity> {
    return this.emailTemplatesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailTemplateEntity>) => r.body as EmailTemplateEntity),
    );
  }

  /**
   * Path part for operation emailTemplatesRemove
   */
  static readonly EmailTemplatesRemovePath = '/email-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailTemplatesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EmailTemplateEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplatesService.EmailTemplatesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmailTemplateEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailTemplatesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailTemplatesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<EmailTemplateEntity> {
    return this.emailTemplatesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailTemplateEntity>) => r.body as EmailTemplateEntity),
    );
  }

  /**
   * Path part for operation emailTemplatesUpdate
   */
  static readonly EmailTemplatesUpdatePath = '/email-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailTemplatesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailTemplatesUpdate$Response(
    params: {
      id: string;
      body: UpdateEmailTemplateDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<EmailTemplateEntity>> {
    const rb = new RequestBuilder(this.rootUrl, EmailTemplatesService.EmailTemplatesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmailTemplateEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailTemplatesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailTemplatesUpdate(
    params: {
      id: string;
      body: UpdateEmailTemplateDto;
    },
    context?: HttpContext,
  ): Observable<EmailTemplateEntity> {
    return this.emailTemplatesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailTemplateEntity>) => r.body as EmailTemplateEntity),
    );
  }
}
