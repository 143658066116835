/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InitiatePaymentIntentDto } from '../models/initiate-payment-intent-dto';
import { StripeInitiatePaymentIntentEntity } from '../models/stripe-initiate-payment-intent-entity';
import { StripeWebhookEntity } from '../models/stripe-webhook-entity';

@Injectable({
  providedIn: 'root',
})
export class PluginsStripeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation stripeInitiatePaymentIntent
   */
  static readonly StripeInitiatePaymentIntentPath = '/plugins/stripe/initiatePaymentIntent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeInitiatePaymentIntent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeInitiatePaymentIntent$Response(
    params: {
      body: InitiatePaymentIntentDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<StripeInitiatePaymentIntentEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PluginsStripeService.StripeInitiatePaymentIntentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StripeInitiatePaymentIntentEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stripeInitiatePaymentIntent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeInitiatePaymentIntent(
    params: {
      body: InitiatePaymentIntentDto;
    },
    context?: HttpContext,
  ): Observable<StripeInitiatePaymentIntentEntity> {
    return this.stripeInitiatePaymentIntent$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeInitiatePaymentIntentEntity>) => r.body as StripeInitiatePaymentIntentEntity),
    );
  }

  /**
   * Path part for operation stripeWebhook
   */
  static readonly StripeWebhookPath = '/plugins/stripe/webhook';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeWebhook()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeWebhook$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<StripeWebhookEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PluginsStripeService.StripeWebhookPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StripeWebhookEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stripeWebhook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeWebhook(params?: {}, context?: HttpContext): Observable<StripeWebhookEntity> {
    return this.stripeWebhook$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeWebhookEntity>) => r.body as StripeWebhookEntity),
    );
  }
}
