/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateRegistrationRuleDto } from '../models/create-registration-rule-dto';
import { RegistrationRuleEntity } from '../models/registration-rule-entity';
import { UpdateRegistrationRuleDto } from '../models/update-registration-rule-dto';
import { UpdateRegistrationRulesDto } from '../models/update-registration-rules-dto';

@Injectable({
  providedIn: 'root',
})
export class RegistrationRulesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation registrationRulesFindAll
   */
  static readonly RegistrationRulesFindAllPath = '/registration-rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationRuleEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationRuleEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesFindAll(params?: {}, context?: HttpContext): Observable<Array<RegistrationRuleEntity>> {
    return this.registrationRulesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationRuleEntity>>) => r.body as Array<RegistrationRuleEntity>),
    );
  }

  /**
   * Path part for operation registrationRulesCreate
   */
  static readonly RegistrationRulesCreatePath = '/registration-rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesCreate$Response(
    params: {
      body: CreateRegistrationRuleDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationRuleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationRuleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesCreate(
    params: {
      body: CreateRegistrationRuleDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationRuleEntity> {
    return this.registrationRulesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationRuleEntity>) => r.body as RegistrationRuleEntity),
    );
  }

  /**
   * Path part for operation registrationRulesUpdateMany
   */
  static readonly RegistrationRulesUpdateManyPath = '/registration-rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesUpdateMany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesUpdateMany$Response(
    params: {
      body: UpdateRegistrationRulesDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationRuleEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesUpdateManyPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationRuleEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesUpdateMany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesUpdateMany(
    params: {
      body: UpdateRegistrationRulesDto;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationRuleEntity>> {
    return this.registrationRulesUpdateMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationRuleEntity>>) => r.body as Array<RegistrationRuleEntity>),
    );
  }

  /**
   * Path part for operation registrationRulesFindOne
   */
  static readonly RegistrationRulesFindOnePath = '/registration-rules/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationRuleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationRuleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationRuleEntity> {
    return this.registrationRulesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationRuleEntity>) => r.body as RegistrationRuleEntity),
    );
  }

  /**
   * Path part for operation registrationRulesRemove
   */
  static readonly RegistrationRulesRemovePath = '/registration-rules/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationRuleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationRuleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationRulesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationRuleEntity> {
    return this.registrationRulesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationRuleEntity>) => r.body as RegistrationRuleEntity),
    );
  }

  /**
   * Path part for operation registrationRulesUpdate
   */
  static readonly RegistrationRulesUpdatePath = '/registration-rules/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationRulesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesUpdate$Response(
    params: {
      id: string;
      body: UpdateRegistrationRuleDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationRuleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationRulesService.RegistrationRulesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationRuleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationRulesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationRulesUpdate(
    params: {
      id: string;
      body: UpdateRegistrationRuleDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationRuleEntity> {
    return this.registrationRulesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationRuleEntity>) => r.body as RegistrationRuleEntity),
    );
  }
}
