/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UpdateUserSessionDto } from '../models/update-user-session-dto';
import { UserSessionEntity } from '../models/user-session-entity';

@Injectable({
  providedIn: 'root',
})
export class UserSessionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation userSessionsFindAll
   */
  static readonly UserSessionsFindAllPath = '/user-sessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSessionsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<UserSessionEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, UserSessionsService.UserSessionsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserSessionEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSessionsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsFindAll(params?: {}, context?: HttpContext): Observable<Array<UserSessionEntity>> {
    return this.userSessionsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserSessionEntity>>) => r.body as Array<UserSessionEntity>),
    );
  }

  /**
   * Path part for operation userSessionsFindOne
   */
  static readonly UserSessionsFindOnePath = '/user-sessions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSessionsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserSessionEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UserSessionsService.UserSessionsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserSessionEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSessionsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserSessionEntity> {
    return this.userSessionsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionEntity>) => r.body as UserSessionEntity),
    );
  }

  /**
   * Path part for operation userSessionsRemove
   */
  static readonly UserSessionsRemovePath = '/user-sessions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSessionsRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserSessionEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UserSessionsService.UserSessionsRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserSessionEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSessionsRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSessionsRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<UserSessionEntity> {
    return this.userSessionsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionEntity>) => r.body as UserSessionEntity),
    );
  }

  /**
   * Path part for operation userSessionsUpdate
   */
  static readonly UserSessionsUpdatePath = '/user-sessions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSessionsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSessionsUpdate$Response(
    params: {
      id: string;
      body: UpdateUserSessionDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<UserSessionEntity>> {
    const rb = new RequestBuilder(this.rootUrl, UserSessionsService.UserSessionsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserSessionEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSessionsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSessionsUpdate(
    params: {
      id: string;
      body: UpdateUserSessionDto;
    },
    context?: HttpContext,
  ): Observable<UserSessionEntity> {
    return this.userSessionsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionEntity>) => r.body as UserSessionEntity),
    );
  }
}
