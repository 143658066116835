/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePunchDto } from '../models/create-punch-dto';
import { CreateRegistrationDto } from '../models/create-registration-dto';
import { PassEntity } from '../models/pass-entity';
import { RegistrationEntity } from '../models/registration-entity';
import { UpdateRegistrationDto } from '../models/update-registration-dto';

@Injectable({
  providedIn: 'root',
})
export class RegistrationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation registrationsFindAll
   */
  static readonly RegistrationsFindAllPath = '/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindAll(params?: {}, context?: HttpContext): Observable<Array<RegistrationEntity>> {
    return this.registrationsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationEntity>>) => r.body as Array<RegistrationEntity>),
    );
  }

  /**
   * Path part for operation registrationsCreate
   */
  static readonly RegistrationsCreatePath = '/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreate$Response(
    params: {
      body: CreateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreate(
    params: {
      body: CreateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsCreateWithPunch
   */
  static readonly RegistrationsCreateWithPunchPath = '/registrations/punch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsCreateWithPunch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreateWithPunch$Response(
    params: {
      body: CreateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsCreateWithPunchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsCreateWithPunch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreateWithPunch(
    params: {
      body: CreateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsCreateWithPunch$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsFindOne
   */
  static readonly RegistrationsFindOnePath = '/registrations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsRemove
   */
  static readonly RegistrationsRemovePath = '/registrations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsUpdate
   */
  static readonly RegistrationsUpdatePath = '/registrations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsUpdate$Response(
    params: {
      id: string;
      body: UpdateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsUpdate(
    params: {
      id: string;
      body: UpdateRegistrationDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsFindValidPasses
   */
  static readonly RegistrationsFindValidPassesPath = '/registrations/{id}/valid-passes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsFindValidPasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindValidPasses$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PassEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsFindValidPassesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsFindValidPasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsFindValidPasses(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<PassEntity>> {
    return this.registrationsFindValidPasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassEntity>>) => r.body as Array<PassEntity>),
    );
  }

  /**
   * Path part for operation registrationsCreatePunch
   */
  static readonly RegistrationsCreatePunchPath = '/registrations/{id}/punch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsCreatePunch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreatePunch$Response(
    params: {
      id: string;
      body: CreatePunchDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsCreatePunchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsCreatePunch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registrationsCreatePunch(
    params: {
      id: string;
      body: CreatePunchDto;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsCreatePunch$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }

  /**
   * Path part for operation registrationsRemovePunch
   */
  static readonly RegistrationsRemovePunchPath = '/registrations/{id}/punch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registrationsRemovePunch()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsRemovePunch$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RegistrationEntity>> {
    const rb = new RequestBuilder(this.rootUrl, RegistrationsService.RegistrationsRemovePunchPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registrationsRemovePunch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registrationsRemovePunch(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<RegistrationEntity> {
    return this.registrationsRemovePunch$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationEntity>) => r.body as RegistrationEntity),
    );
  }
}
