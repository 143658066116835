/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTimezoneDto } from '../models/create-timezone-dto';
import { TimezoneEntity } from '../models/timezone-entity';
import { UpdateTimezoneDto } from '../models/update-timezone-dto';

@Injectable({
  providedIn: 'root',
})
export class TimezonesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation timezonesFindAll
   */
  static readonly TimezonesFindAllPath = '/timezones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timezonesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<TimezoneEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, TimezonesService.TimezonesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TimezoneEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timezonesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesFindAll(params?: {}, context?: HttpContext): Observable<Array<TimezoneEntity>> {
    return this.timezonesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimezoneEntity>>) => r.body as Array<TimezoneEntity>),
    );
  }

  /**
   * Path part for operation timezonesCreate
   */
  static readonly TimezonesCreatePath = '/timezones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timezonesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timezonesCreate$Response(
    params: {
      body: CreateTimezoneDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimezoneEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TimezonesService.TimezonesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TimezoneEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timezonesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timezonesCreate(
    params: {
      body: CreateTimezoneDto;
    },
    context?: HttpContext,
  ): Observable<TimezoneEntity> {
    return this.timezonesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimezoneEntity>) => r.body as TimezoneEntity),
    );
  }

  /**
   * Path part for operation timezonesFindOne
   */
  static readonly TimezonesFindOnePath = '/timezones/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timezonesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimezoneEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TimezonesService.TimezonesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TimezoneEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timezonesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<TimezoneEntity> {
    return this.timezonesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimezoneEntity>) => r.body as TimezoneEntity),
    );
  }

  /**
   * Path part for operation timezonesRemove
   */
  static readonly TimezonesRemovePath = '/timezones/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timezonesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimezoneEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TimezonesService.TimezonesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TimezoneEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timezonesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timezonesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<TimezoneEntity> {
    return this.timezonesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimezoneEntity>) => r.body as TimezoneEntity),
    );
  }

  /**
   * Path part for operation timezonesUpdate
   */
  static readonly TimezonesUpdatePath = '/timezones/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timezonesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timezonesUpdate$Response(
    params: {
      id: string;
      body: UpdateTimezoneDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimezoneEntity>> {
    const rb = new RequestBuilder(this.rootUrl, TimezonesService.TimezonesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TimezoneEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timezonesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timezonesUpdate(
    params: {
      id: string;
      body: UpdateTimezoneDto;
    },
    context?: HttpContext,
  ): Observable<TimezoneEntity> {
    return this.timezonesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimezoneEntity>) => r.body as TimezoneEntity),
    );
  }
}
