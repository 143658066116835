/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePaymentMethodDto } from '../models/create-payment-method-dto';
import { PaymentMethodEntity } from '../models/payment-method-entity';
import { UpdatePaymentMethodDto } from '../models/update-payment-method-dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation paymentMethodsFindAll
   */
  static readonly PaymentMethodsFindAllPath = '/payment-methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PaymentMethodEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PaymentMethodEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsFindAll(params?: {}, context?: HttpContext): Observable<Array<PaymentMethodEntity>> {
    return this.paymentMethodsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodEntity>>) => r.body as Array<PaymentMethodEntity>),
    );
  }

  /**
   * Path part for operation paymentMethodsCreate
   */
  static readonly PaymentMethodsCreatePath = '/payment-methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentMethodsCreate$Response(
    params: {
      body: CreatePaymentMethodDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PaymentMethodEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentMethodEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentMethodsCreate(
    params: {
      body: CreatePaymentMethodDto;
    },
    context?: HttpContext,
  ): Observable<PaymentMethodEntity> {
    return this.paymentMethodsCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodEntity>) => r.body as PaymentMethodEntity),
    );
  }

  /**
   * Path part for operation paymentMethodsFindOne
   */
  static readonly PaymentMethodsFindOnePath = '/payment-methods/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PaymentMethodEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentMethodEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PaymentMethodEntity> {
    return this.paymentMethodsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodEntity>) => r.body as PaymentMethodEntity),
    );
  }

  /**
   * Path part for operation paymentMethodsRemove
   */
  static readonly PaymentMethodsRemovePath = '/payment-methods/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PaymentMethodEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentMethodEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentMethodsRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PaymentMethodEntity> {
    return this.paymentMethodsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodEntity>) => r.body as PaymentMethodEntity),
    );
  }

  /**
   * Path part for operation paymentMethodsUpdate
   */
  static readonly PaymentMethodsUpdatePath = '/payment-methods/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentMethodsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentMethodsUpdate$Response(
    params: {
      id: string;
      body: UpdatePaymentMethodDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PaymentMethodEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentMethodsService.PaymentMethodsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentMethodEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentMethodsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentMethodsUpdate(
    params: {
      id: string;
      body: UpdatePaymentMethodDto;
    },
    context?: HttpContext,
  ): Observable<PaymentMethodEntity> {
    return this.paymentMethodsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodEntity>) => r.body as PaymentMethodEntity),
    );
  }
}
