/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePassCategoryDto } from '../models/create-pass-category-dto';
import { PassCategoryEntity } from '../models/pass-category-entity';
import { ReorderPassCategoriesDto } from '../models/reorder-pass-categories-dto';
import { UpdatePassCategoryDto } from '../models/update-pass-category-dto';

@Injectable({
  providedIn: 'root',
})
export class PassCategoriesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation passCategoriesFindAll
   */
  static readonly PassCategoriesFindAllPath = '/pass-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PassCategoryEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassCategoryEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesFindAll(params?: {}, context?: HttpContext): Observable<Array<PassCategoryEntity>> {
    return this.passCategoriesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassCategoryEntity>>) => r.body as Array<PassCategoryEntity>),
    );
  }

  /**
   * Path part for operation passCategoriesCreate
   */
  static readonly PassCategoriesCreatePath = '/pass-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesCreate$Response(
    params: {
      body: CreatePassCategoryDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesCreate(
    params: {
      body: CreatePassCategoryDto;
    },
    context?: HttpContext,
  ): Observable<PassCategoryEntity> {
    return this.passCategoriesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassCategoryEntity>) => r.body as PassCategoryEntity),
    );
  }

  /**
   * Path part for operation passCategoriesFindOne
   */
  static readonly PassCategoriesFindOnePath = '/pass-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassCategoryEntity> {
    return this.passCategoriesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassCategoryEntity>) => r.body as PassCategoryEntity),
    );
  }

  /**
   * Path part for operation passCategoriesRemove
   */
  static readonly PassCategoriesRemovePath = '/pass-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passCategoriesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassCategoryEntity> {
    return this.passCategoriesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassCategoryEntity>) => r.body as PassCategoryEntity),
    );
  }

  /**
   * Path part for operation passCategoriesUpdate
   */
  static readonly PassCategoriesUpdatePath = '/pass-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesUpdate$Response(
    params: {
      id: string;
      body: UpdatePassCategoryDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassCategoryEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassCategoryEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesUpdate(
    params: {
      id: string;
      body: UpdatePassCategoryDto;
    },
    context?: HttpContext,
  ): Observable<PassCategoryEntity> {
    return this.passCategoriesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassCategoryEntity>) => r.body as PassCategoryEntity),
    );
  }

  /**
   * Path part for operation passCategoriesReorder
   */
  static readonly PassCategoriesReorderPath = '/pass-categories/reorder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passCategoriesReorder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesReorder$Response(
    params: {
      body: ReorderPassCategoriesDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PassCategoryEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassCategoriesService.PassCategoriesReorderPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassCategoryEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passCategoriesReorder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passCategoriesReorder(
    params: {
      body: ReorderPassCategoriesDto;
    },
    context?: HttpContext,
  ): Observable<Array<PassCategoryEntity>> {
    return this.passCategoriesReorder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassCategoryEntity>>) => r.body as Array<PassCategoryEntity>),
    );
  }
}
