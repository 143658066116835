/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PermissionEntity } from '../models/permission-entity';
import { UpdatePermissionDto } from '../models/update-permission-dto';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation permissionsFindLevels
   */
  static readonly PermissionsFindLevelsPath = '/permissions/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsFindLevels()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsFindLevels$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, PermissionsService.PermissionsFindLevelsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsFindLevels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsFindLevels(params?: {}, context?: HttpContext): Observable<Array<string>> {
    return this.permissionsFindLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>),
    );
  }

  /**
   * Path part for operation permissionsUpdate
   */
  static readonly PermissionsUpdatePath = '/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsUpdate$Response(
    params: {
      id: string;
      body: UpdatePermissionDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PermissionEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PermissionsService.PermissionsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PermissionEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsUpdate(
    params: {
      id: string;
      body: UpdatePermissionDto;
    },
    context?: HttpContext,
  ): Observable<PermissionEntity> {
    return this.permissionsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PermissionEntity>) => r.body as PermissionEntity),
    );
  }
}
