/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateSettingDto } from '../models/create-setting-dto';
import { SettingEntity } from '../models/setting-entity';
import { UpdateSettingDto } from '../models/update-setting-dto';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation settingsCreate
   */
  static readonly SettingsCreatePath = '/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingsCreate$Response(
    params: {
      body: CreateSettingDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<SettingEntity>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SettingEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingsCreate(
    params: {
      body: CreateSettingDto;
    },
    context?: HttpContext,
  ): Observable<SettingEntity> {
    return this.settingsCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingEntity>) => r.body as SettingEntity),
    );
  }

  /**
   * Path part for operation settingsReloadSettings
   */
  static readonly SettingsReloadSettingsPath = '/settings/{category}/reload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsReloadSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsReloadSettings$Response(
    params: {
      category: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsReloadSettingsPath, 'post');
    if (params) {
      rb.path('category', params.category, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsReloadSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsReloadSettings(
    params: {
      category: string;
    },
    context?: HttpContext,
  ): Observable<boolean> {
    return this.settingsReloadSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean),
    );
  }

  /**
   * Path part for operation settingsFindAll
   */
  static readonly SettingsFindAllPath = '/settings/{category}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsFindAll$Response(
    params: {
      category: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<SettingEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsFindAllPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SettingEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsFindAll(
    params: {
      category: string;
    },
    context?: HttpContext,
  ): Observable<Array<SettingEntity>> {
    return this.settingsFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingEntity>>) => r.body as Array<SettingEntity>),
    );
  }

  /**
   * Path part for operation settingsFindOne
   */
  static readonly SettingsFindOnePath = '/settings/{category}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsFindOne$Response(
    params: {
      category: string;
      key: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<SettingEntity>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsFindOnePath, 'get');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('key', params.key, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SettingEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsFindOne(
    params: {
      category: string;
      key: string;
    },
    context?: HttpContext,
  ): Observable<SettingEntity> {
    return this.settingsFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingEntity>) => r.body as SettingEntity),
    );
  }

  /**
   * Path part for operation settingsRemove
   */
  static readonly SettingsRemovePath = '/settings/{category}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsRemove$Response(
    params: {
      category: string;
      key: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<SettingEntity>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsRemovePath, 'delete');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('key', params.key, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SettingEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settingsRemove(
    params: {
      category: string;
      key: string;
    },
    context?: HttpContext,
  ): Observable<SettingEntity> {
    return this.settingsRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingEntity>) => r.body as SettingEntity),
    );
  }

  /**
   * Path part for operation settingsUpdate
   */
  static readonly SettingsUpdatePath = '/settings/{category}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settingsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingsUpdate$Response(
    params: {
      category: string;
      key: string;
      body: UpdateSettingDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<SettingEntity>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.SettingsUpdatePath, 'patch');
    if (params) {
      rb.path('category', params.category, {});
      rb.path('key', params.key, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SettingEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settingsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settingsUpdate(
    params: {
      category: string;
      key: string;
      body: UpdateSettingDto;
    },
    context?: HttpContext,
  ): Observable<SettingEntity> {
    return this.settingsUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingEntity>) => r.body as SettingEntity),
    );
  }
}
