import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { AlertController, Platform } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { AppEventService } from './services/app-event.service';
import { PreferencesService } from './services/preferences.service';
import { Network } from '@capacitor/network';

export type ArrayElement<A> = A extends (infer T)[] ? T : never;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  loading = true;
  language = 'en';
  theme = 'light';
  loggedIn = false;
  noNetwork = false;

  constructor(
    private platform: Platform,
    public storage: PreferencesService,
    public appEvent: AppEventService,
    public router: Router,
    public api: ApiService,
    public alertCtrl: AlertController,
    public ngZone: NgZone,
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    // check initial state
    await Promise.all([this.checkServer(), this.checkLoginStatus(), this.checkTheme(), this.checkLanguage()]);

    // listen for login/logout events
    this.appEvent.listen('user:login').subscribe(async (sessionId: string) => {
      await this.updateLoggedInStatus(true, sessionId);
    });

    this.appEvent.listen('user:logout').subscribe(async () => {
      await this.updateLoggedInStatus(false);
      this.api.unsetLoggedInUser();
    });

    // listen for server change events
    this.appEvent.listen('api:setup').subscribe((uri: string) => {
      this.api.setRootUrl(uri);
    });
  }

  initializeApp() {
    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active?', isActive);
    });

    App.addListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
    });

    App.addListener('appRestoredResult', (data) => {
      console.log('Restored state:', data);
    });

    Network.addListener('networkStatusChange', async (status) => {
      console.log('Network status changed', status);

      if (status.connected === false) {
        this.noNetwork = true;
      } else {
        this.noNetwork = false;
      }
    });

    this.platform.ready().then(() => {
      SplashScreen.hide();
      StatusBar.show().catch(() => {});
    });
  }

  async checkServer() {
    const apiUri = await this.storage.getServer();

    if (apiUri) {
      this.api.setRootUrl(apiUri);
    }
  }

  async checkLoginStatus() {
    const sessionId = await this.storage.getSessionId();

    this.updateLoggedInStatus(!!sessionId, sessionId);

    this.loading = false;
  }

  async checkTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.toggleDarkTheme(prefersDark.matches);
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
  }

  async checkLanguage() {
    const userLanguage = await this.storage.getLanguage();
    if (userLanguage) {
      this.language = userLanguage;
    } else {
      this.language = 'en';
      await this.storage.setLanguage('en');
    }
  }

  async updateLoggedInStatus(loggedIn: boolean, sessionId?: string) {
    if (loggedIn) {
      this.api.setToken(sessionId);
      await this.api.setLoggedInUser();
    } else {
      this.api.setToken('');
      this.api.unsetLoggedInUser();
    }
    this.loggedIn = loggedIn;
  }

  async logout() {
    const confirm = await Dialog.confirm({
      title: 'Logout',
      message: 'Are you sure?',
    });
    if (confirm.value === true) {
      this.api.account
        .logout()
        .subscribe()
        .add(async () => {
          await this.storage.logout();
          this.ngZone.run(() => {
            this.router.navigate(['/auth/login']);
          });
        });
    }
  }

  async themeChange() {
    let darkTheme = false;
    if (this.theme === 'dark') {
      darkTheme = true;
    } else {
      this.theme = 'light';
    }
    this.toggleDarkTheme(darkTheme);
    await this.storage.setTheme(this.theme);
  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  listenForAppEvents = () => {
    App.addListener('appUrlOpen', () => {});
  };
}
