/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PassEntity } from '../models/pass-entity';
import { PunchEntity } from '../models/punch-entity';
import { UpdatePassDto } from '../models/update-pass-dto';

@Injectable({
  providedIn: 'root',
})
export class PassesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation passesFindAll
   */
  static readonly PassesFindAllPath = '/passes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<PassEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassesService.PassesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindAll(params?: {}, context?: HttpContext): Observable<Array<PassEntity>> {
    return this.passesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassEntity>>) => r.body as Array<PassEntity>),
    );
  }

  /**
   * Path part for operation passesFindOne
   */
  static readonly PassesFindOnePath = '/passes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassesService.PassesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassEntity> {
    return this.passesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassEntity>) => r.body as PassEntity),
    );
  }

  /**
   * Path part for operation passesRemove
   */
  static readonly PassesRemovePath = '/passes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassesService.PassesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassEntity> {
    return this.passesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassEntity>) => r.body as PassEntity),
    );
  }

  /**
   * Path part for operation passesUpdate
   */
  static readonly PassesUpdatePath = '/passes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passesUpdate$Response(
    params: {
      id: string;
      body: UpdatePassDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassesService.PassesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passesUpdate(
    params: {
      id: string;
      body: UpdatePassDto;
    },
    context?: HttpContext,
  ): Observable<PassEntity> {
    return this.passesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassEntity>) => r.body as PassEntity),
    );
  }

  /**
   * Path part for operation passesFindPunches
   */
  static readonly PassesFindPunchesPath = '/passes/{id}/punches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passesFindPunches()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindPunches$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<PunchEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassesService.PassesFindPunchesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PunchEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passesFindPunches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passesFindPunches(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<PunchEntity>> {
    return this.passesFindPunches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchEntity>>) => r.body as Array<PunchEntity>),
    );
  }
}
