/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePassTypeDto } from '../models/create-pass-type-dto';
import { PassTypeEntity } from '../models/pass-type-entity';
import { RegistrationRuleEntity } from '../models/registration-rule-entity';
import { UpdatePassTypeDto } from '../models/update-pass-type-dto';

@Injectable({
  providedIn: 'root',
})
export class PassTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation passTypesFindAll
   */
  static readonly PassTypesFindAllPath = '/pass-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<PassTypeEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PassTypeEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindAll(params?: {}, context?: HttpContext): Observable<Array<PassTypeEntity>> {
    return this.passTypesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PassTypeEntity>>) => r.body as Array<PassTypeEntity>),
    );
  }

  /**
   * Path part for operation passTypesCreate
   */
  static readonly PassTypesCreatePath = '/pass-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passTypesCreate$Response(
    params: {
      body: CreatePassTypeDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassTypeEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassTypeEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passTypesCreate(
    params: {
      body: CreatePassTypeDto;
    },
    context?: HttpContext,
  ): Observable<PassTypeEntity> {
    return this.passTypesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassTypeEntity>) => r.body as PassTypeEntity),
    );
  }

  /**
   * Path part for operation passTypesFindOne
   */
  static readonly PassTypesFindOnePath = '/pass-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassTypeEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassTypeEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassTypeEntity> {
    return this.passTypesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassTypeEntity>) => r.body as PassTypeEntity),
    );
  }

  /**
   * Path part for operation passTypesRemove
   */
  static readonly PassTypesRemovePath = '/pass-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassTypeEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassTypeEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<PassTypeEntity> {
    return this.passTypesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassTypeEntity>) => r.body as PassTypeEntity),
    );
  }

  /**
   * Path part for operation passTypesUpdate
   */
  static readonly PassTypesUpdatePath = '/pass-types/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passTypesUpdate$Response(
    params: {
      id: string;
      body: UpdatePassTypeDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PassTypeEntity>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PassTypeEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passTypesUpdate(
    params: {
      id: string;
      body: UpdatePassTypeDto;
    },
    context?: HttpContext,
  ): Observable<PassTypeEntity> {
    return this.passTypesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PassTypeEntity>) => r.body as PassTypeEntity),
    );
  }

  /**
   * Path part for operation passTypesFindRegistrationRules
   */
  static readonly PassTypesFindRegistrationRulesPath = '/pass-types/{id}/registration-rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passTypesFindRegistrationRules()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindRegistrationRules$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RegistrationRuleEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, PassTypesService.PassTypesFindRegistrationRulesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RegistrationRuleEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passTypesFindRegistrationRules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  passTypesFindRegistrationRules(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<Array<RegistrationRuleEntity>> {
    return this.passTypesFindRegistrationRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationRuleEntity>>) => r.body as Array<RegistrationRuleEntity>),
    );
  }
}
