export const definedEvents = [
  'api:setup',
  'changeEmail:initiated',
  'changeEmail:completed',
  'cart:updated',
  'password:updated',
  'scheduleFilter:changed',
  'user:login:completed',
  'user:login:initiated',
  'user:login',
  'user:logout',
  'user:signup:completed',
  'user:signup:initiated',
  'user:signup',
  'profile:modified',
  'registration:added',
  'registration:removed',
] as const;

export type DefinedEvents = (typeof definedEvents)[number];
