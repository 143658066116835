import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { definedEvents, DefinedEvents } from './app-events/app-events';

@Injectable({
  providedIn: 'root',
})
export class AppEventService {
  private eventSubjects: Subject<unknown>[];
  private eventObservables$: Observable<unknown>[];

  constructor() {
    this.eventSubjects = definedEvents.map(() => new Subject());
    this.eventObservables$ = this.eventSubjects.map((subject) => subject.asObservable());
  }

  listen<T = unknown>(path: DefinedEvents): Observable<T> {
    return this.eventObservables$[definedEvents.indexOf(path)] as Observable<T>;
  }

  emit<T = unknown>(path: DefinedEvents, data?: T): void {
    // we could log events here
    return this.eventSubjects[definedEvents.indexOf(path)].next(data as T);
  }
}
