/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateGenderDto } from '../models/create-gender-dto';
import { GenderEntity } from '../models/gender-entity';
import { UpdateGenderDto } from '../models/update-gender-dto';

@Injectable({
  providedIn: 'root',
})
export class GendersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation gendersFindAll
   */
  static readonly GendersFindAllPath = '/genders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gendersFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersFindAll$Response(params?: {}, context?: HttpContext): Observable<StrictHttpResponse<Array<GenderEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, GendersService.GendersFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<GenderEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gendersFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersFindAll(params?: {}, context?: HttpContext): Observable<Array<GenderEntity>> {
    return this.gendersFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GenderEntity>>) => r.body as Array<GenderEntity>),
    );
  }

  /**
   * Path part for operation gendersCreate
   */
  static readonly GendersCreatePath = '/genders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gendersCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gendersCreate$Response(
    params: {
      body: CreateGenderDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<GenderEntity>> {
    const rb = new RequestBuilder(this.rootUrl, GendersService.GendersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GenderEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gendersCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gendersCreate(
    params: {
      body: CreateGenderDto;
    },
    context?: HttpContext,
  ): Observable<GenderEntity> {
    return this.gendersCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenderEntity>) => r.body as GenderEntity),
    );
  }

  /**
   * Path part for operation gendersFindOne
   */
  static readonly GendersFindOnePath = '/genders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gendersFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<GenderEntity>> {
    const rb = new RequestBuilder(this.rootUrl, GendersService.GendersFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GenderEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gendersFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<GenderEntity> {
    return this.gendersFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenderEntity>) => r.body as GenderEntity),
    );
  }

  /**
   * Path part for operation gendersRemove
   */
  static readonly GendersRemovePath = '/genders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gendersRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<GenderEntity>> {
    const rb = new RequestBuilder(this.rootUrl, GendersService.GendersRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GenderEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gendersRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gendersRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<GenderEntity> {
    return this.gendersRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenderEntity>) => r.body as GenderEntity),
    );
  }

  /**
   * Path part for operation gendersUpdate
   */
  static readonly GendersUpdatePath = '/genders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gendersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gendersUpdate$Response(
    params: {
      id: string;
      body: UpdateGenderDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<GenderEntity>> {
    const rb = new RequestBuilder(this.rootUrl, GendersService.GendersUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GenderEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gendersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gendersUpdate(
    params: {
      id: string;
      body: UpdateGenderDto;
    },
    context?: HttpContext,
  ): Observable<GenderEntity> {
    return this.gendersUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GenderEntity>) => r.body as GenderEntity),
    );
  }
}
