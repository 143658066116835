import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth/signup',
    loadChildren: () => import('./pages/auth/auth-signup/auth-signup.module').then((m) => m.AuthSignupPageModule),
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./pages/auth/auth-login/auth-login.module').then((m) => m.AuthLoginPageModule),
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () =>
      import('./pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'auth/confirm/:task/:email',
    loadChildren: () => import('./pages/auth/confirm-user/confirm-user.module').then((m) => m.ConfirmUserPageModule),
  },
  {
    path: 'auth/confirm/:task',
    loadChildren: () => import('./pages/auth/confirm-user/confirm-user.module').then((m) => m.ConfirmUserPageModule),
  },
  {
    path: 'auth/confirm',
    loadChildren: () => import('./pages/auth/confirm-user/confirm-user.module').then((m) => m.ConfirmUserPageModule),
  },
  {
    path: 'auth/claim-account/:api/:email/:code',
    loadChildren: () => import('./pages/auth/claim-account/claim-account.module').then((m) => m.ClaimAccountPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
