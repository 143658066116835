/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateStaffRoleDto } from '../models/create-staff-role-dto';
import { StaffRoleEntity } from '../models/staff-role-entity';
import { UpdateStaffRoleDto } from '../models/update-staff-role-dto';

@Injectable({
  providedIn: 'root',
})
export class StaffRolesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation staffRolesFindAll
   */
  static readonly StaffRolesFindAllPath = '/staff-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRolesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesFindAll$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<StaffRoleEntity>>> {
    const rb = new RequestBuilder(this.rootUrl, StaffRolesService.StaffRolesFindAllPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StaffRoleEntity>>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRolesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesFindAll(params?: {}, context?: HttpContext): Observable<Array<StaffRoleEntity>> {
    return this.staffRolesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StaffRoleEntity>>) => r.body as Array<StaffRoleEntity>),
    );
  }

  /**
   * Path part for operation staffRolesCreate
   */
  static readonly StaffRolesCreatePath = '/staff-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRolesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffRolesCreate$Response(
    params: {
      body: CreateStaffRoleDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<StaffRoleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffRolesService.StaffRolesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StaffRoleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRolesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffRolesCreate(
    params: {
      body: CreateStaffRoleDto;
    },
    context?: HttpContext,
  ): Observable<StaffRoleEntity> {
    return this.staffRolesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StaffRoleEntity>) => r.body as StaffRoleEntity),
    );
  }

  /**
   * Path part for operation staffRolesFindOne
   */
  static readonly StaffRolesFindOnePath = '/staff-roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRolesFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<StaffRoleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffRolesService.StaffRolesFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StaffRoleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRolesFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesFindOne(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StaffRoleEntity> {
    return this.staffRolesFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<StaffRoleEntity>) => r.body as StaffRoleEntity),
    );
  }

  /**
   * Path part for operation staffRolesRemove
   */
  static readonly StaffRolesRemovePath = '/staff-roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRolesRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<StaffRoleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffRolesService.StaffRolesRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StaffRoleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRolesRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staffRolesRemove(
    params: {
      id: string;
    },
    context?: HttpContext,
  ): Observable<StaffRoleEntity> {
    return this.staffRolesRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<StaffRoleEntity>) => r.body as StaffRoleEntity),
    );
  }

  /**
   * Path part for operation staffRolesUpdate
   */
  static readonly StaffRolesUpdatePath = '/staff-roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staffRolesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffRolesUpdate$Response(
    params: {
      id: string;
      body: UpdateStaffRoleDto;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<StaffRoleEntity>> {
    const rb = new RequestBuilder(this.rootUrl, StaffRolesService.StaffRolesUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<StaffRoleEntity>;
        }),
      );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staffRolesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staffRolesUpdate(
    params: {
      id: string;
      body: UpdateStaffRoleDto;
    },
    context?: HttpContext,
  ): Observable<StaffRoleEntity> {
    return this.staffRolesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StaffRoleEntity>) => r.body as StaffRoleEntity),
    );
  }
}
